main{
  min-height: 80vh;
}
.button-round {
  border-radius: 10px;
}

@font-face {
  font-family: 'Barlow Condensed SemiBold';
  src: url('./Fonts/BarlowCondensed-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Header title */
.slide-title {
  font-family: 'Barlow Condensed SemiBold', sans-serif;
  font-size: 60px;
  font-weight: bold; /* Ensure it applies the correct weight */
  line-height: 1.2;  /* Optional: adjust line height for better spacing */
  color:  #4B3335;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #40e3d0;
  border-radius: 50%;
  opacity: 0.8;
  padding: 10px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control-next-icon:after,
.carousel-control-prev-icon:after {
  color: white;
  font-size: 100px; /* Adjust as needed */
  line-height: 1;
}

@keyframes customBounce {
  0%, 7% { transform: scale(1,1) translateY(-20%); }
  2%, 10% { transform: scale(1.1,.9) translateY(0); }
  13%, 17%, 25% { transform: scale(.9,1.1) translateY(-20%); }
  16%, 25%, 35%, 100% { transform: scale(1.05,.95) translateY(0); }
}

.FaIcon {
  animation: customBounce 7s infinite;
  cursor: pointer;
}

/* CustomDatePickerReservationSums */
.warning-indicator {
  position: absolute;
  background-color: red;
  width: 10px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
  border-radius: 50%;
  top: -2px; /* Adjust as needed */
  left: -2px; /* Adjust as needed */
}

.stick_it {
  position: sticky;
  top: 0;
}

/* SubMenu CSS */

.submenu-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.submenu-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms, transform 1000ms;
}

.submenu-exit {
  opacity: 1;
  transform: translateY(0);
}

.submenu-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}
.arrow-icon {
  color: #4ca974; /* Set the color for the icon */
  transition: transform .2s ease;
}

.rotate-menu-arrow {
  transform: rotate(180deg) ;
}

.modern-menu {
  width: 100%;
  height: 40vh;
  background: #f7fffc;
  border-right: 1px solid #dee2e6;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;
  margin-top: 10px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.menu-top {
  padding: 8px;
  background-color: #8FEBC5;
}

.menu-left {
  background-color: #EE754C;
  width:17px;
  height: 100%;
  left:0;
  top:0;
  position:relative;
}

.modern-menu .nav-link {
  position: relative;
  color: #495057;
  margin-bottom: .5rem;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s;
  max-height: 45px;
}

.modern-menu .nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 1px;
  background: #495057;
  transition: all 0.3s;
}

.modern-menu .nav-link:hover,
.modern-menu .nav-link.active {
  color: #12ffb0;
}

.modern-menu .nav-link:hover:after,
.modern-menu .nav-link.active:after {
  left: 0;
  width: 100%;
  background: #12ffb0;
}

/* Hide menu and show button on small screens */
@media (max-width: 1200px) { 
  .modern-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 540px;
    transform: translateY(97%);
  }
  .modern-menu.show {
    transform: translateY(25%);
  }
  .activate_scroll {
    overflow: auto;
    max-height: 300px;
    
    /* this is the default color of the scrollbar */
    scrollbar-color: #888 #f2f2f2;
  
    /* this is the width of the scrollbar */
    scrollbar-width: thin;
  }
  
  .activate_scroll::-webkit-scrollbar {
    /* this is the width of the scrollbar */
    width: 8px;
  }
  
  .activate_scroll::-webkit-scrollbar-track {
    /* this is the color of the track, where the scrollbar moves */
    background: #f2f2f2;
  }
  
  .activate_scroll::-webkit-scrollbar-thumb {
    /* this is the color of the scrollbar itself */
    background: #888;
  }
  
  .activate_scroll::-webkit-scrollbar-thumb:hover {
    /* this is the color of the scrollbar when hovered */
    background: #555;
  }
  .space_for_menu {
    padding: 50px;
    background-color: white;
  }
  .menu-button {
    display: block;
    position: fixed;
    bottom: -10px;
    padding: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    border: none;
    background: white;
    color: white;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s;
    border-radius: 50%;
  }
}

/* Hide button and show menu on larger screens */
@media (min-width: 1200px) { 
  .modern-menu {
    position: static;
    transform: none;
    height: 100vh;
  }

  .space_for_menu {
    display: none;
  }

  .menu-button {
    display: none;
  }
}

.rotate {
  transform: rotate(180deg) translateX(50%);
}

/*  Manager Creation */

.selected-restaurant {
  background-color: #32ed99;
  color: white;
  cursor: pointer;
}

.restaurant-list {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  overflow-y: scroll;
  background-color: white;
}

.restaurant-item {
  border: 1px solid #27a888;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  height: 39px;
  padding: 4px;
  margin: 4px;
  flex-shrink: 0;
}

 /* GridItems */

 .item-list {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
}

.grid-item {
  border: 1px solid #27a888;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  height: 39px;
  padding: 4px;
  margin: 4px;
  flex-shrink: 0;
}

/* DropDownButton */

.form-control-people {
  display: flex;
  border-radius: 5px;
  width: 150px;
  border: 1px solid rgb(170, 170, 170);
  text-align: center;
  height: 40px;
  cursor:pointer;
  padding:0 ;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  position: relative;
}

.custom-dropdown-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  top:38px;
  left:0px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  z-index: 3000;
  border: 1px solid #ebebeb;
  padding: 0;
  min-height: 20px ;
  max-height: 200px;
  overflow: auto;
  /* this is the default color of the scrollbar */
  scrollbar-color: #888 #f2f2f2;

  /* this is the width of the scrollbar */
  scrollbar-width: thin;
}

.custom-dropdown-menu::-webkit-scrollbar {
  /* this is the width of the scrollbar */
  width: 8px;
}

.custom-dropdown-menu::-webkit-scrollbar-track {
  /* this is the color of the track, where the scrollbar moves */
  background: #f2f2f2;
}

.custom-dropdown-menu::-webkit-scrollbar-thumb {
  /* this is the color of the scrollbar itself */
  background: #888;
  border-radius: 5px;
}

.custom-dropdown-menu::-webkit-scrollbar-thumb:hover {
  /* this is the color of the scrollbar when hovered */
  background: #555;
}

.arrow-icon-dropdown {
  color: #4ca974; /* Set the color for the icon */
  transition: transform .2s ease;
  position: absolute;
  top: 4px;
  right:2px;
}

.dropdown-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height:20px;
  width:100%;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 13px;
  color:gray;
}

.dropdown-menu-item:hover {
  background-color: #57be8e;
}

.dropdown-menu-item.selected {
  background-color: #57be8e;
}

/* Reservation screen */

.frontModal {
  z-index: 5000 !important; 
}

.frontModal .modal-backdrop {
  z-index: 4999 !important;
}


 /* Datepicker */
.react-datepicker__header {
  text-align: left; /* Ensure it aligns to the start */
  padding: 0 !important;
  margin:0 !important;
}

.react-datepicker__day-name {
  display: flex;
  justify-content: center; /* Aligns the text to the start */
  padding: 0 0 10px 0 !important; /* Adjust as needed for spacing */
  margin: 0 9px !important; /* Add horizontal margins for spacing */
  gap:10px;
}



/* Header title */
.header_title {
  height: 100%;
  font-size: 35px;
  font-family: 'Arial-bold', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  margin: 0;
}
.react-datepicker__day{
  background-color: inherit !important;
  opacity: 1 !important;
  transform: none !important;
  box-shadow: none !important;
}

/* checkmark */
.makeItGreen {
  color:#32ed99 !important;
  background-color: white !important;
}

.makeItGreen:hover {
  color:#32ed99 !important;
}

/* Reservations from restaurant screen */
.acceptRejectModal {
  max-height: 200px;
  overflow: auto;
  /* this is the default color of the scrollbar */
  scrollbar-color: #888 #f2f2f2;
  /* this is the width of the scrollbar */
  scrollbar-width: thin;
  }

  .calendarBottom {
    padding-bottom:15px !important;
    }
  

  @keyframes reduceCircle {
    to {
        stroke-dashoffset: 0;
    }
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
}

.map-container {
  height: 400px; /* Set to desired height */
  width: 100%; /* Set to desired width */
}

.map-container-customer-view {
  height: 100%; /* Set to desired height */
  width: 100%; /* Set to desired width */
  cursor: pointer !important;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}


svg-color {
  stroke: #ffffff !important;
}